const date = () => {

  const today = new Date();
  const yearContainer = document.querySelector('.video__date');
  const options = { year: 'numeric', month: 'long', day: 'numeric' };

  const todayDate = today.toLocaleString('en-US', options);

  if (yearContainer) {
    yearContainer.textContent = todayDate;
  }
};


export { date };
